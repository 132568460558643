<template>
	<div class="broadcastManage">
		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table
				:data="broadData"
				style="width: 100%;"
				v-loading="loading"
				ref="multipleTable"
			>
				<el-table-column prop="phone" label="手机号"></el-table-column>
				<el-table-column prop="createTime" label="申请时间"></el-table-column>
				<el-table-column label="认证类型">
                    <template slot-scope="scope">
						<div>{{scope.row.accountType == 3 ? '个人认证' : '企业认证'}}</div>
					</template>
                </el-table-column>
				<el-table-column label="状态">
                    <template slot-scope="scope">
						<div v-if="scope.row.accountType == 3">
                            {{scope.row.status == 1 ? '认证中' : scope.row.status == 2 ? '认证成功' : '认证失败'}}
                        </div>
                        <div v-if="scope.row.accountType == 2">
                            {{scope.row.companyStatus == 1 ? '认证中' : scope.row.status == 2 ? '认证成功' : '认证失败'}}
                        </div>
					</template>
                </el-table-column>
				
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<buttonPermissions :datas="'recordDetial'">
                            <span
								style="color:#02a7f0cc;"
								@click="toDetial(scope.row)"
							>查看详情</span>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>

            <div style="text-align: right;">
				<el-pagination
					v-if="total"
					style="margin:20px 0"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageNo"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import {
    routingRecord,
    // applicationTransfer,
    // withdrawApply
} from '@/api/finance'
import buttonPermissions from '@/components/buttonPermissions';

export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			broadData: [],
            loading: false,
            total:0,   //总条数
            pageNo:0,  //当前页码
            pageSize:20,    //当前页显示数据条数
		}
	},
	created () {
		this.getList();
	},
	methods: {
		// 获取列表
		async getList () {
            console.log(12);
			try {
                this.loading = true;
                let data = {
                    pageNo:this.pageNo,
                    pageSize:this.pageSize,
                }
				let result = await routingRecord(data);
                this.broadData = result.data.records || [];
                this.total = result.data.total;
			} finally {
				this.loading = false
			}

        },
        //查看详情
        toDetial(row) {
            this.$router.push({ path: '/finance/record/detial?id='+row.id +'&accountType='+row.accountType});
        },
        // 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},

		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		},
	}
}
</script>

<style lang="less">
.broadcastManage {
	width: 100%;
	background: #fff;
	padding: 15px;
	.el-card__body {
		padding-top: 0px !important;
	}
	.pointers {
		cursor: pointer;
	}

	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.number__input {
		border: 1px solid #ccc;
	}
	.transfercontent {
		// justify-content: ;
		flex-wrap: wrap;
		.item_content {
			width: 25%;
			margin-top: 45px;
			.num {
				margin-top: 10px;
			}
		}
	}
}
</style>
